import React, { useState } from 'react';
import './Footer.css';
import facebook from '../../Assets/Images/facebook-1.png'
import linkedin from '../../Assets/Images/linkedin-1.png'
import behance from '../../Assets/Images/behance.png'
import dribble from '../../Assets/Images/dribble.png'
import { TbLocationCheck } from 'react-icons/tb';
import { Link } from 'react-router-dom'

const Footer = () => {
  // State to manage which menu is active
  const [activeMenu, setActiveMenu] = useState('Company');

  // Function to handle menu clicks and set the active menu
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  return (
    <div>
      <div className="menu" style={{ display: 'flex' }}>
        <div
          onClick={() => handleMenuClick('Company')}
          className={`secms1 ${activeMenu === 'Company' ? 'active' : ''}`}
        >
          <h1>Marketing</h1>
        </div>
        <div
          onClick={() => handleMenuClick('Services')}
          className={`secms1 ${activeMenu === 'Services' ? 'active' : ''}`}
        >
          <h1>Services</h1>
        </div>
        <div
          onClick={() => handleMenuClick('Industries')}
          className={`secms1 ${activeMenu === 'Industries' ? 'active' : ''}`}
        >
          <h1>Industries</h1>
        </div>
        <div
          onClick={() => handleMenuClick('Portfolio')}
          className={`secms1 ${activeMenu === 'Portfolio' ? 'active' : ''}`}
        >
          <h1>Blockchain</h1>
        </div>
      </div>
      <div className="underline"></div>

      {/* Render different content based on activeMenu */}
      {activeMenu === 'Company' && (
        <div className="w3-container menus">
          <div className="blockrow1" style={{ padding: '34px', display: 'flex' }}>
            <div>
              <li className="blv"><Link to="/marketing/seo">SEO</Link></li>
            </div>
            <div>
              <div className="linesv"></div>
            </div>
            <div>
              <li><Link to="/marketing/blogger-outreach">Blogger Outreach Services</Link></li>
            </div>
          </div>
        </div>
      )}

      {activeMenu === 'Services' && (
        <div className="w3-container menus">
          {/* Add sections for each Service Category */}
          <div className="blockrow1">
            <div className="container">
              <div className="row" id="footer-row">
                <div className="col-md-4">
                  <div>
                    <h1>Native Apps</h1>
                    <ul>
                      <li><Link to="/services/native-apps/Mobile-development">Mobile App Development</Link></li>
                      <li><Link to="/services/native-apps/ios">Native IOS App</Link></li>
                      <li><Link to="/services/android">Android App Development</Link></li>
                      <li><Link to="/services/wearable">Wearable App Development</Link></li>
                    </ul>
                  </div>
                  {/* Additional sections go here */}


                </div>
                <div className="col-md-4">
                  <div>
                    <h1>Cross Platform Apps</h1>
                    <ul>
                      <li><Link to="/services/native-apps/ios">Xamarin</Link></li>
                      <li><Link to="/services/native-apps/ios">React Native</Link></li>
                      <li><Link to="/services/native-apps/ios">Flutter</Link></li>
                    </ul>
                  </div>

                </div>
                <div className="col-md-4">
                  <div>
                    <h1>Gaming Apps</h1>
                    <ul>
                      <li><Link to="/services/unity-gaming">Unity Engine</Link></li>
                      <li><Link to="/services/unity-gaming">2D Games/3D Games</Link></li>
                      <li><Link to="/services/unity-gaming">AR/VR Apps</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row" id="footer-row">
                <div className="col-md-4">
                  <div>
                    <h1>Web Apps</h1>
                    <ul>
                      <li><Link to="http://localhost/ibsmobile-app-development-company/">Mobile App Development</Link></li>
                      <li><Link to="http://localhost/ibsnative-ios-app-2/">Native IOS App</Link></li>
                      <li><Link to="http://localhost/ibsandroid-app-development/">Android App Development</Link></li>
                      <li><Link to="http://localhost/ibswearable-app-development/">Wearable App Development</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <h1>Website Development</h1>
                    <ul>
                      <li><Link to="/services/website">Angular</Link></li>
                      <li><Link to="/services/website">React JS</Link></li>
                      <li><Link to="/services/website">PHP</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4">
                  <div>
                    <h1>Desingning</h1>
                    <ul>
                      <li><Link to="/services/designing">UI/UX Design</Link></li>
                      <li><Link to="/services/designing">Graphic Design</Link></li>
                      <li><Link to="/services/designing">Logo Design</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {activeMenu === 'Industries' && (
        <div className="w3-container menus">
          <div className="blockrow1" style={{ display: 'flex' }}>
            {/* Similar structure for Industries */}
            <div>
              <ul>
                <li><Link to="http://localhost/ibstop-e-commerce-app-development/">E-Commerce</Link></li>
                <li><Link to="http://localhost/ibsgrocery-app-development/">Grocery</Link></li>
                <li><Link to="http://localhost/ibseducation-app-development/">Education</Link></li>
              </ul>
            </div>

            <div>
              <ul>
                <li><Link to="http://localhost/ibstop-e-commerce-app-development/">Games</Link></li>
                <li><Link to="http://localhost/ibsgrocery-app-development/">On-Demand</Link></li>
                <li><Link to="http://localhost/ibseducation-app-development/">Events</Link></li>
              </ul>
            </div>

            <div>
              <ul>
                <li><Link to="http://localhost/ibstop-e-commerce-app-development/">Fitness</Link></li>
                <li><Link to="http://localhost/ibsgrocery-app-development/">Tour & Travels</Link></li>
                <li><Link to="http://localhost/ibseducation-app-development/">Transport</Link></li>
              </ul>
            </div>

            <div>
              <ul>
                <li><Link to="http://localhost/ibstop-e-commerce-app-development/">Helthcare</Link></li>
                <li><Link to="http://localhost/ibsgrocery-app-development/">Finance</Link></li>
                <li><Link to="http://localhost/ibseducation-app-development/">Real-State</Link></li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {activeMenu === 'Portfolio' && (
        <div className="w3-container menus">
          <div className="blockrow1" style={{ display: 'flex' }}>
            <div>
              <h1>Blockchain Development</h1>
              <ul>
                <li><Link to="http://localhost/ibsblockchain-dev/">Blockchain Development</Link></li>
                <li><Link to="http://localhost/ibsaltcoin-dev-services/">Altcoin Development Services</Link></li>
                <li><Link to="http://localhost/ibsmultichain-blockchain/">Multichain Blockchain</Link></li>
                <li><Link to="http://localhost/ibsaltcoin-dev-services/">Crypto MLM Software Development</Link></li>
                <li><Link to="http://localhost/ibsmultichain-blockchain/">Smart Contract Development</Link></li>
                <li><Link to="http://localhost/ibsaltcoin-dev-services/">Crypto Wallet Development</Link></li>
                <li><Link to="http://localhost/ibsmultichain-blockchain/">Crypto Exchange Services</Link></li>
                {/* Other Portfolio items go here */}
              </ul>
            </div>

            <div>
              <h1>Token Development</h1>
              <ul>
                <li><Link to="http://localhost/ibsblockchain-dev/">Crypto Token Development</Link></li>
                <li><Link to="http://localhost/ibsaltcoin-dev-services/">ERC-20 Token Develpment</Link></li>
                <li><Link to="http://localhost/ibsmultichain-blockchain/">Ethereum Token Development</Link></li>
                <li><Link to="http://localhost/ibsmultichain-blockchain/">Solana Token Development</Link></li>
                <li><Link to="http://localhost/ibsmultichain-blockchain/">Tron Token Development
                </Link></li>

                {/* Other Portfolio items go here */}
              </ul>
            </div>

            <div>
              <h1>Other Development</h1>
              <ul>
                <li><Link to="http://localhost/ibsblockchain-dev/">Crypto White Paper Development</Link></li>
                <li><Link to="http://localhost/ibsaltcoin-dev-services/">Polygon Blockchain Development</Link></li>
                {/* Other Portfolio items go here */}
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="social-icons">
        <div className="icon">
          <img src={facebook} alt="" />        </div>
        <div className="icon">
          <a href="https://www.linkedin.com/company/interest-bud-solutions-pvt-ltd/mycompany/verification/" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="" />
          </a>
        </div>
        <div className="icon">
          <a href="https://www.behance.net/InterestBudSolutions" target="_blank" rel="noopener noreferrer">
            <img src={behance} alt="" />
          </a>
        </div>
        <div className="icon">
          <a href="https://dribbble.com/InterestBud_Solutions" target='blank'>
            <img src={dribble} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
