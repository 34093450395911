import React, { useState } from "react";
import "../Button/Button.css"; // Import the CSS file
import PopupForm from "../../Components/NewPopUpButton/NewPopUpButton";

const Button = (props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="consult-container">
      <button className="consult-button" onClick={handleButtonClick}>
        {props.text} <i className="fas fa-angle-right" id="angle-icon"></i>
      </button>
      <PopupForm isOpen={isPopupOpen} onClose={handleClosePopup} />
    </div>
  );
};

export default Button;
