import React, { useState } from 'react';
import '../MobileHeader/MobileHeader.css';
import { Link } from 'react-router-dom';

import logoimg from '../../Assets/Images/logo.png';
import logoimgs from '../../Assets/Images/IBS-Logo_Full-1-1.png';

const MobileHeader = () => {
    const [isVisible, setIsVisible] = useState(false); // State to track visibility of menu
    const [isSubmenuVisible, setIsSubmenuVisible] = useState(false); // State to track visibility of Marketing submenu
    const [isServicesSubmenuVisible, setIsServicesSubmenuVisible] = useState(false); // State to track visibility of Services submenu
    const [isBlockchainSubmenuVisible, setIsBlockchainSubmenuVisible] = useState(false); // State to track visibility of Blockchain submenu

    const handlenevToggle = () => {
        setIsVisible(!isVisible); // Toggle the visibility of the menu
    };

    const handleSubmenuToggle = () => {
        if (isSubmenuVisible) {
            setIsSubmenuVisible(false);
        } else {
            setIsSubmenuVisible(true);
            setIsServicesSubmenuVisible(false);
            setIsBlockchainSubmenuVisible(false);
        }
    };

    const handleServicesSubmenuToggle = () => {
        if (isServicesSubmenuVisible) {
            setIsServicesSubmenuVisible(false);
        } else {
            setIsServicesSubmenuVisible(true);
            setIsSubmenuVisible(false);
            setIsBlockchainSubmenuVisible(false);
        }
    };

    const handleBlockchainSubmenuToggle = () => {
        if (isBlockchainSubmenuVisible) {
            setIsBlockchainSubmenuVisible(false);
        } else {
            setIsBlockchainSubmenuVisible(true);
            setIsSubmenuVisible(false);
            setIsServicesSubmenuVisible(false);
        }
    };

    const closeMenu = () => {
        setIsVisible(false); // Close the entire menu
        setIsSubmenuVisible(false);
        setIsServicesSubmenuVisible(false);
        setIsBlockchainSubmenuVisible(false);
    };

    return (
        <div className="mobhdrc">
            {/* Overlay Div */}
            {isVisible && <div className="nevoverly" onClick={handlenevToggle}></div>}

            <section style={{ height: '0vh', marginTop: '0%' }}>
                <div className="nev-container nevigation">
                    <div className="brand">
                        <Link to="">
                            <img src={logoimg} alt="Logo" />
                        </Link>
                    </div>
                    <div
                        className="tabimg1"
                        style={{
                            width: '35%',
                            marginLeft: '19%',
                        }}
                    >
                        <Link to="" className="tabimg">
                            <img src={logoimgs} alt="Tab Logo" />
                        </Link>
                    </div>
                    <div className="nev-mobile" onClick={handlenevToggle}>
                        <Link to="#" id="nevbar-toggle">
                            <span></span>
                        </Link>
                    </div>
                    <div className="bg" style={{ display: isVisible ? 'block' : 'none' }}>
                        <ul className="nev-list anv">
                            <li>
                                <Link to="/about" onClick={closeMenu}>About</Link>
                            </li>
                            <li>
                                <Link to="#!" onClick={handleSubmenuToggle} className="dropmenu">
                                    Marketing
                                </Link>
                                {isSubmenuVisible && (
                                    <ul className="nevbar-dropdown">
                                        <li>
                                            <Link to="/marketing/seo" onClick={closeMenu}>SEO</Link>
                                        </li>
                                        <li>
                                            <Link to="/marketing/blogger-outreach" onClick={closeMenu}>
                                                Blogger Outreach Services
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <Link to="#!" onClick={handleServicesSubmenuToggle} className="dropmenu">
                                    Services
                                </Link>
                                {isServicesSubmenuVisible && (
                                    <ul className="nevbar-dropdown">
                                        <h1>Native Apps</h1>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>Mobile App Development</Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>Native IOS App</Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <Link to="#!" onClick={handleBlockchainSubmenuToggle} className="dropmenu">
                                    Blockchain & Token
                                </Link>
                                {isBlockchainSubmenuVisible && (
                                    <ul className="nevbar-dropdown">
                                        <h1>Blockchain Development</h1>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>
                                                Blockchain Development Company
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#" onClick={closeMenu}>
                                                Altcoin Development Services
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                            </li>
                            <li>
                                <Link to="" onClick={closeMenu}>Portfolio</Link>
                            </li>
                            <li>
                                <Link to="/contact" onClick={closeMenu}>Contact Us</Link>
                            </li>
                        </ul>
                        <div className="linediv"></div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default MobileHeader;
