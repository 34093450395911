import React, { useState, useEffect } from 'react';
import '../FooterUpper/FooterUpper.css';
import headoffice from '../../Assets/Images/Group-3417.png';
import israel from '../../Assets/Images/Group-3419.png';
import uk from '../../Assets/Images/Group-3426.png';
import uae from '../../Assets/Images/Group-3423.png';
import ukraine from '../../Assets/Images/Group-3429.png';

import headoffice_color from '../../Assets/Images/Group-3435.png';
import israel_color from '../../Assets/Images/Group-3434.png';
import ukraine_color from '../../Assets/Images/Group-3431.png';
import uae_color from '../../Assets/Images/Group-3432.png';
import uk_color from '../../Assets/Images/Group-3433.png';




import telephone from '../../Assets/Images/Image-45.png';
import arrow from '../../Assets/Images/Image-46.png';
import ibslogo from '../../Assets/Images/ibslogo.png';
import refreshIcon from '../../Assets/Images/reload.png';

const FooterUpper = () => {
    const textStyle = {
        color: '#44476a',
        fontWeight: 600,
        lineHeight: '20px',
        margin: '0px',
        fontSize: '16px'
    };
    const paraStyle = {
        fontSize: '13px',
        color: '#000000 !important',
        marginTop: '10px',
        fontWeight: 500,
    };

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        mobileNumber: '',
        message: '',
        captchaAnswer: '',
    });
    const [errors, setErrors] = useState({});
    const [captcha, setCaptcha] = useState(generateCaptcha());

    function generateCaptcha() {
        const num1 = Math.floor(Math.random() * 10) + 1;
        const num2 = Math.floor(Math.random() * 10) + 1;
        return { question: `${num1} + ${num2}`, answer: num1 + num2 };
    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const validate = () => {
        const errors = {};
        if (!formData.fullName) errors.fullName = 'Full Name is required.';
        if (!formData.email) errors.email = 'Email is required.';
        else if (!/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Email is invalid.';
        if (!formData.mobileNumber) errors.mobileNumber = 'Mobile Number is required.';
        else if (!/^\d{10}$/.test(formData.mobileNumber)) errors.mobileNumber = 'Enter a valid 10-digit Mobile Number.';
        if (!formData.message) errors.message = 'Message is required.';
        if (!formData.captchaAnswer) errors.captchaAnswer = 'Captcha answer is required.';
        else if (parseInt(formData.captchaAnswer) !== captcha.answer) errors.captchaAnswer = 'Incorrect captcha answer.';

        setErrors(errors);

        // Set a timeout to clear errors after 2 seconds
        if (Object.keys(errors).length > 0) {
            setTimeout(() => setErrors({}), 2000);
        }

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            alert('Form submitted successfully!');
            setFormData({ fullName: '', email: '', mobileNumber: '', message: '', captchaAnswer: '' });
            setCaptcha(generateCaptcha());
            setErrors({});
        }
    };

    const refreshCaptcha = () => {
        setCaptcha(generateCaptcha());
        setFormData({ ...formData, captchaAnswer: '' }); // Clear the captcha answer input
    };

    return (
        <div>
            <div className="container">
                <div className="row upper-footer-main">
                    <div className="col-md-1"></div>
                    <div className="col-md-2">
                        <div class="mychngeimg">
                            <img src={headoffice} alt="Head Office" />
                            <img src={headoffice_color} alt="Head Office" className='none'/>
                            <h5 style={textStyle}>INDIA<br />(Head Office)</h5>
                            <p style={paraStyle}>E-212, Sector 63 Noida 201301</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="mychngeimg">
                            <img src={israel} alt="Israel Office" />
                            <img src={israel_color} alt="Head Office" className='none'/>
                            <h5 style={textStyle}>ISRAEL<br />(Head Office)</h5>
                            <p style={paraStyle}>Rabbi Meir Baal Hanes, 30 A Tel Aviv Israel</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="mychngeimg">
                            <img src={ukraine} alt="UK Office" />
                            <img src={ukraine_color} alt="Head Office" className='none'/>
                            <h5 style={textStyle}>United Kingdom<br />(Corporate Office)</h5>
                            <p style={paraStyle}>R/0 34, CROWN WALK, Wembley United Kingdom, HA98HU</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="mychngeimg">
                            <img src={uae} alt="UAE Office" />
                            <img src={uae_color} alt="UAE Office" className='none'/>
                            <h5 style={textStyle}>UAE</h5>
                            <p style={paraStyle}>Coming Soon</p>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div class="mychngeimg">
                            <img src={uk} alt="Ukraine Office" />
                            <img src={uk_color} alt="Ukraine Office" className='none'/>
                            <h5 style={textStyle}>UKRAINE</h5>
                            <p style={paraStyle}>Coming Soon</p>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6">
                        <img src={telephone} alt="" id="telephone" />
                        <div className="et_pb_module et_pb_text et_pb_text_1_tb_footer contctsecf et_pb_text_align_left et_pb_bg_layout_light">
                            <div className="et_pb_text_inner">
                                <h4>Write To Us</h4>
                                <h2>support@interestbudsolutions.com</h2>
                                <h4>We are just a call away</h4>
                                <h2>+91 8076569119 (Sales)</h2>
                                <h2>+91 9001761400 (HR)</h2>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 left-con">
                        <div className="left-con-sec-1">
                            <img src={arrow} alt="" style={{ float: 'inline-end' }} />
                            <h3>
                                We can’t wait to connect with you and embark on something extraordinary together. Feel free to reach out to us with any questions or inquiries—let’s make it happen!
                            </h3>
                        </div>
                        <div id="footer-form">
                            <form onSubmit={handleSubmit} id="footer-forms">
                                <div className="form-row">
                                    <input
                                        type="text"
                                        name="fullName"
                                        placeholder="Full Name"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                    /><br />
                                    {errors.fullName && <small className="error">{errors.fullName}</small>}
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email ID"
                                        value={formData.email}
                                        onChange={handleChange}
                                    /><br />
                                    {errors.email && <small className="error">{errors.email}</small>}
                                </div>
                                <input
                                    type="tel"
                                    name="mobileNumber"
                                    placeholder="Mobile Number"
                                    value={formData.mobileNumber}
                                    onChange={handleChange}
                                />
                                {errors.mobileNumber && <small className="error">{errors.mobileNumber}</small>}
                                <textarea
                                    name="message"
                                    placeholder="Your message"
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>
                                {errors.message && <small className="error">{errors.message}</small>}
                                <div className="captcha-section">
                                    <label>{`What is ${captcha.question} ?`}</label>
                                    <img
                                        src={refreshIcon}
                                        alt="Refresh Captcha"
                                        onClick={refreshCaptcha}
                                        style={{ cursor: 'pointer', marginLeft: '10px', width: '24px', height: '24px' }}
                                    />
                                    <input
                                        type="text"
                                        name="captchaAnswer"
                                        placeholder="Type your answer"
                                        value={formData.captchaAnswer}
                                        onChange={handleChange}
                                        id="answers"
                                    />
                                    {errors.captchaAnswer && <small className="error">{errors.captchaAnswer}</small>}
                                </div>
                                <button type="submit">Submit</button>
                            </form>
                        </div>
                    </div>
                    <center><img src={ibslogo} alt="" id='ibs-logo' /></center>
                </div>
            </div>
        </div>
    );
}

export default FooterUpper;
