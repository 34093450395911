import React from 'react'
import '../Floatingicons/Floatingicons.css'
const FloatingIcons = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div class="iconxsx">
                            <div class="iconx-container google">
                                <div class="iconx"></div>
                            </div>
                            <div class="iconx-container microsoft">
                                <div class="iconx"></div>
                            </div>
                            <div class="iconx-container bitbucket">
                                <div class="iconx"></div>
                            </div>
                            <div class="iconx-container digitalocean">
                                <div class="iconx" ></div>
                            </div>
                            <div class="iconx-container ubuntu">
                                <div class="iconx" ></div>
                            </div>
                            <div class="iconx-container nginx">
                                <div class="iconx" ></div>
                            </div>
                            <div class="iconx-container github">
                                <div class="iconx"></div>
                            </div>
                            <div class="iconx-container facebook">
                                <div class="iconx" ></div>
                            </div>
                            <div class="iconx-container wordpress">
                                <div class="iconx" ></div>
                            </div>
                            <div class="iconx-container wordpress1">
                                <div class="iconx" ></div>
                            </div>
                            <div class="iconx-container wordpress2">
                                <div class="iconx" ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FloatingIcons;
