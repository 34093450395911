import React, { useState, useEffect } from 'react';
import './MobilePortfolio.css';

const MobilePortfolioComp = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  const list = [
    {
      name: "Dale3joa3ak (Food App)",
      desc:
        "Dale3 Jo3ak is a platform that helps you find and order food from wherever you are. Install the mobile application, type in an address; we list the restaurants that deliver to that location, other user’s ratings.",
        img:
        "http://localhost/ibswp-content/uploads/2024/01/Dale3.png",
      thumbImg:
      "http://localhost/ibswp-content/uploads/2024/01/Group-3014.png",
      additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
       additionalLink1: "https://play.google.com/store/apps/details?id=com.mobiwhiz.dale3jo3ak",  // Add this line
      additionalLink2: "https://apps.apple.com/in/app/dale3-jo3ak/id1504892575" ,
    },
    {
      name: "MyMood AI (Photo Generator)",
      desc:
      "Prepare to unlock the limitless potential of My Mood Ai's cutting-edge AI technology! Our versatile AI photo generator, also serving as an enhancer, editor, and picture generator, empowers you to effortlessly transform your selfies into photorealistic avatars. Think of it as having a professional photographer, AI art generator, avatar maker, and AI photo editor all in your pocket!",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/MymoodAI.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3012.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
       additionalLink1: "https://play.google.com/store/apps/details?id=app.mymoodai&hl=en-IN",  // Add this line
    //  additionalLink2: "" ,
    },
    {
      name: "MunchO(Instant Car Rental App)",
      desc:
      "MunchO makes your life easier when you plan to order your food online. Muncho is the latest in the industry and is catching up popularity with its one stop app interface. The app gives you an interface to view multiple nearby restaurants, digital menus, most recommended/favourite dishes, photos, user reviews so you can order your food quickly and conveniently. The application provides you with the following features.",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/Getaround.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/04/circle.png",
       additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
      additionalLink1: "https://play.google.com/store/apps/details?id=com.muncho.india&hl=en",  // Add this line
      // additionalLink2: "https://apps.apple.com/us/app/getaround-instant-car-rental/id412017926" ,
    },
    {
      name: "Kisanwala(The Farmer's App)",
      desc:
      "Kisanwala, aim to make farmers self-reliant with modern day technology, information, high quality inputs, and support by providing customized solutions catering to the needs of each farm and farmer.",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/Kisan-wala.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3017.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        additionalLink1: "https://play.google.com/store/apps/details?id=com.kisanwala",  // Add this line
      // additionalLink2: "#" ,
    },
    {
      name: "Tangoride(Ride app)",
      desc:
      "Tangoride is a ridesharing and carpooling app, but it’s also intended to be a gateway to a community of likeminded people. Drivers are able to offer instant or scheduled lifts to fellow passengers who are heading in a similar direction.",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/Tango-Ride.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3018.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        additionalLink1: "https://play.google.com/store/apps/details?id=com.app.taxi.tangoride",  // Add this line
      // additionalLink2: "https://apps.apple.com/in/app/tangoride-carpooling/id1449951780" ,
    },
    {
      name: "Safelet(safety band app)",
      desc:
      "Safelet , the company behind the Safelet Bracelet. Safelet allows you to notify your friends, family and the police where you are and that you need help.",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/safelet.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3019.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        additionalLink1: "https://play.google.com/store/apps/details?id=com.safelet.android",  // Add this line
      additionalLink2: "https://apps.apple.com/in/app/safelet-safety-bracelet/id1079472866" ,
    },
    {
      name: "Carmudi SFA",
      desc:
      "The application facilitates the users to check in at assigned dealership, office, customer visits and visits to new dealerships. Other than allowing the user to check at specific locations the app also facilitates the following-Register filed visit activities precisely Set visits followup at assigned dealership.",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/Carmudi.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3020.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        additionalLink1: "https://play.google.com/store/apps/details?id=com.int.sfa.ph&hl=en-IN",  // Add this line
      // additionalLink2: "#" ,
    },
    {
      name: "Hinow(Private Video Chat app)",
      desc:
      "Hinow is a wonderful online video chat app for you to chat with lots of talented Stars⭐ and new friends all over the world 🌐. One on One Video Chat, Random Video Chat and Text Chat connect with anyone from anywhere in the world with a single tap! It is a really easy and fast way to meet new people!",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/Hinow.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3021.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        additionalLink1: "https://play.google.com/store/apps/details?id=tv.hinow.mobile&hl=en&gl=US",  // Add this line
      // additionalLink2: "#" ,
    },
    /* {
      name: "Purplle(Online Beauty Shopping)",
      desc:
      "The Purplle app is a very pocket-friendly app with Beauty  Cosmetic products having various top brands such as Mamaearth, Good Vibes, Lakme, Maybelline, L’Oreal, Sugar many more.",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/Purplle.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3022.png",
        additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      // additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        additionalLink1: "https://play.google.com/store/apps/details?id=com.manash.purplle&hl=en_IN&gl=US",  // Add this line
      // additionalLink2: "#" ,
    }, */
    {
      name: "u_paid_m(Billing app)",
      desc:
      "We are on a mission to lift 1.5 billion people above the poverty line by eradicating banking and payment processing payment charges amounting today to $23 per year. Businessmen, open an account with us in 3 easy steps. That is all it takes to start transacting with your customers or....",
      img:
        "http://localhost/ibswp-content/uploads/2024/01/U_paid_M.png",
      thumbImg:
        "http://localhost/ibswp-content/uploads/2024/01/Group-3023.png",
        // additionalImage1: "http://localhost/ibswp-content/uploads/2024/01/Group-3058.png",
      additionalImage2: "http://localhost/ibswp-content/uploads/2024/01/Group-3057.png",
        // additionalLink1: "https://play.google.com/store/apps/details?id=com.u_paid_m",  // Add this line
      additionalLink2: "https://apps.apple.com/in/app/u-paid-m/id1601713203" ,
    },
  ];

  const activeInfo = list[activeIndex];

  const itemPosition = list.map((_, key) => {
    const deg = (360 / list.length) * key - 90;
    const radius = "8rem";
    return `rotate(${deg}deg) translate(${radius}) rotate(${-deg}deg)`;
  });

  const handleScroll = (event) => {
    if (isScrolling) return;
    const direction = event.deltaY > 0 ? 1 : -1;
    let newIndex = activeIndex + direction;
    if (newIndex < 0) newIndex = list.length - 1;
    if (newIndex >= list.length) newIndex = 0;
    setActiveIndex(newIndex);
    setIsScrolling(true);
    setTimeout(() => {
      setIsScrolling(false);
    }, 1000);
  };

  // useEffect(() => {
  //   window.addEventListener('wheel', handleScroll);
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, [activeIndex, isScrolling]);

  useEffect(() => {
    // Add 'body' class when the component is mounted
    document.body.classList.add('body');

    window.addEventListener('wheel', handleScroll);

    return () => {
      document.body.classList.remove('body');
      window.removeEventListener('wheel', handleScroll);
    };
  }, [activeIndex, isScrolling]);

  return (
    // <div id="app">
    <> <div className="container" id="portolio">
      <div className="row">
        <div className="col-md-6">
          <div
            className="hero-bg"
            style={{ backgroundImage: `url(${activeInfo.img})` }}
            key={activeIndex}
          ></div>
          <div className="hero-title" key={activeIndex}></div>
          <div className="round">
            {list.map((el, key) => (
              <div
                className={`item ${activeIndex === key ? 'active' : ''}`}
                onClick={() => setActiveIndex(key)}
                key={key}
                style={{ transform: itemPosition[key] }}
              >
                <div className="inner">
                  <img src={el.thumbImg} alt="" />
                </div>
              </div>
            ))}
          </div>

        </div>
        <div className="col-md-6">  {activeInfo && (
          <div className="hero-info" key={activeIndex}>
            <div className="row">
              <div className="content">{activeInfo.name}</div>
            </div>
            <div className="row">
              <div className="content desc">{activeInfo.desc}</div>
            </div>
            <div className="row appbtn">
              {activeInfo.additionalImage1 && (
                <a href={activeInfo.additionalLink1} target="_blank" rel="noreferrer">
                  <img src={activeInfo.additionalImage1} alt="" />
                </a>
              )}
              {activeInfo.additionalImage2 && (
                <a href={activeInfo.additionalLink2} target="_blank" rel="noreferrer">
                  <img src={activeInfo.additionalImage2} alt="" />
                </a>
              )}
            </div>
          </div>
        )}</div>
      </div>
    </div></>
    // </div>
  );
};

export default MobilePortfolioComp;
