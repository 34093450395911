import React, { useState, useEffect } from "react";
import "./NewPopUpButton.css";
import contact from '../../Assets/Images/Contact-us.gif'
import ali from '../../Assets/Images/ALl.png'

const PopupForm = ({ isOpen, onClose }) => {
  const [visible, setVisible] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setVisible(true);
    } else {
      setTimeout(() => setVisible(false), 300); // Delay for animation
    }
  }, [isOpen]);

  if (!visible) return null;

  return (
    <div className={`popup-overlay ${!isOpen ? "hide" : ""}`}>
      <div className="container" id="popup-div">
        <div className="row">
          <div className="col-md-6">
            <div className="popup-images">
              <img src={contact} alt="" />
              <img src={ali} alt=""  id="ali-img"/>
            </div>
          </div>
          <div className="col-md-6">
            <div className={`popup-content ${!isOpen ? "hide" : ""}`}>
              <button className="close-button" onClick={onClose}>
                &times;
              </button>
              <form>
                <div id="first-sec-form">
                  <div>
                    <label>Full Name</label>
                    <input type="text" placeholder="" />
                  </div>
                  <div>
                    <label>Email ID</label>
                    <input type="email" placeholder="" />
                  </div>
                </div>
                <label>Contact Number</label>
                <input type="text" placeholder="" />
                <label>Message</label>
                <textarea placeholder=""></textarea>
                <label>What is 4 + 7?</label>
                <input type="text" placeholder="Type your answer" />
                <button type="submit" className="submit-buttons">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupForm;
