import React, { useState, useEffect,Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./Components/Header/Header";
import loader from "./Assets/Images/logo-1.gif";
import KnowMore from './Components/KnowMore/KnowMore.jsx';
import { AiPage } from "./Pages/AiPage/AiPage.jsx";



// Lazy load the components
const Home = React.lazy(() => import("./Pages/Home/Home"));
const About = React.lazy(() => import("./Pages/About/About"));
const Contact = React.lazy(() => import("./Pages/Contact/Contact"));
const SEO = React.lazy(() => import("./Pages/SEO/SEO.jsx"));
const BloggerOutreach = React.lazy(() => import("./Pages/BloggerOutreach/BloggerOutreach.jsx"));
const MobileApp = React.lazy(() => import("./Pages/MobileApp/MobileApp.jsx"));
const NativeIOS = React.lazy(() => import("./Pages/NativeIOS/NativeIOS.jsx"));
const AndroidPage = React.lazy(() => import("./Pages/Android/Android.jsx"));
const Wearable = React.lazy(() => import("./Pages/Wearable/Wearable.jsx"));
const UnityGaming = React.lazy(() => import("./Pages/UnityGaming/UnityGaming.jsx"));
const Web = React.lazy(() => import("../src/Pages/Web/Web.jsx"));
const UI = React.lazy(() => import("../src/Pages/UI/UX/UI/UX.jsx"));
const BlockchainDevelopment = React.lazy(() => import("./Pages/Blockchain Development/BlockchainDevelopment.jsx"));
const ALTcoinDevelopment = React.lazy(() => import("./Pages/ALTcoinDevelopment/ALTcoinDevelopment.jsx"));
const MultiChainBlockchain = React.lazy(() => import("./Pages/MultiChainBlockchain/MultiChainBlockchain.jsx"));
const CryptoMLMSoftwareDevelopment = React.lazy(() => import("./Pages/CryptoMLMSoftwareDevelopment/CryptoMLMSoftwareDevelopment.jsx"));
const SmartContractDevelopment = React.lazy(() => import("./Pages/SmartContractDevelopment/SmartContractDevelopment.jsx"));
const CryptoWalletDevelopment = React.lazy(() => import("./Pages/CryptoWalletDevelopment/CryptoWalletDevelopment.jsx"));
const CryptocurrencyExchangeDevelopment = React.lazy(() => import("./Pages/CryptocurrencyExchangeDevelopment/CryptocurrencyExchangeDevelopment.jsx"));
const MobilePortfolio = React.lazy(() => import("./Pages/MobilePortfolio/MobilePortfolio.jsx"));
const Portfolio = React.lazy(() => import("./Pages/Portfolio/Portfolio.jsx"));
const CryptoTokenDevelopment = React.lazy(() => import("./Pages/CryptoTokenDevelopment/CryptoTokenDevelopment.jsx"));
const WebsitePortfolio = React.lazy(() => import("./Pages/WebsitePortfolio/WebsitePortfolio.jsx"));
const GamePortfolio = React.lazy(() => import("./Pages/GamePortfolio/GamePortfolio.jsx"));








function App() {
  // const [showLoading, setShowLoading] = useState(false);
  // const location = useLocation();

  // useEffect(() => {
  //   // Show loader on route change
  //   setShowLoading(true);
  //   const timer = setTimeout(() => setShowLoading(false), 2000);

  //   // Cleanup timer
  //   return () => clearTimeout(timer);
  // }, [location]);

  return (
    <>
      <Header />
      <main>
        {/* {showLoading && (
          <div className="loader-container">
            <img
              src={loader}
              alt="Loader"
              className="header-loader"
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            />
          </div>
        )} */}
          <div className="know-more-container">
        <KnowMore />
      </div>
        <Suspense>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/marketing/seo" element={<SEO />} />
            <Route path="/marketing/blogger-outreach" element={<BloggerOutreach />} />
            <Route path="/services/native-apps/Mobile-development" element={<MobileApp />} />
            <Route path="/services/native-apps/ios" element={<NativeIOS />} />
            <Route path="/services/android" element={<AndroidPage />} />
            <Route path="/services/wearable" element={<Wearable />} />
            <Route path="/services/unity-gaming" element={<UnityGaming />} />
            <Route path="/services/website" element={<Web />} />
            <Route path="/services/designing" element={<UI />} />
            <Route path="/services/blockchain-development" element={<BlockchainDevelopment />} />
            <Route path="/services/ALT-coin-development" element={<ALTcoinDevelopment />} />
            <Route path="/services/multichain-blockchain-development" element={<MultiChainBlockchain/>} />
            <Route path="/services/crypto-mlm-software-development" element={<CryptoMLMSoftwareDevelopment/>} />
            <Route path="/services/smart-contract-development" element={<SmartContractDevelopment/>} />
            <Route path="/services/crypto-wallet-development" element={<CryptoWalletDevelopment/>} />
            <Route path="/services/crypto-currency-exchange-development" element={< CryptocurrencyExchangeDevelopment/>} />
            <Route path="/Mobile-Portfolio" element={< MobilePortfolio/>} />
            <Route path="/developing-with-ai" element={< AiPage/>} />
            <Route path="/portfolio" element={< Portfolio/>} />
            <Route path="/Crypto-Token-Development" element={< CryptoTokenDevelopment/>} />
            <Route path="/web-portfolio" element={< WebsitePortfolio/>} />
            <Route path="/game-portfolio" element={< GamePortfolio/>} />
          </Routes>
        </Suspense>
      </main>
    </>
  );
}

export default App;
