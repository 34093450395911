import React from 'react';
import './KnowMore.css';
import { FaLinkedin, FaBehance, FaDribbble, FaFacebook } from 'react-icons/fa';

const KnowMore = () => {
  return (
    <>
      <div className="socialnediaabourt">
        <div className="sideheading"> <span>Know More About Us</span> </div>
        <div className="socialmediaside">
          <ul>
            <li>
              <a
                rel="nofollow noopener"
                href="https://www.linkedin.com/company/68692183/admin/"
                target="_blank"
              >
                <FaLinkedin
                  style={{ color: '#009ae5' }}
                  className="icons social-icon"
                />
              </a>
            </li>
            <li>
              <a
                rel="nofollow noopener"
                href="https://www.behance.net/InterestBudSolutions"
                target="_blank"
              >
                <FaBehance
                  style={{ color: '#3a67fa' }}
                  className="icons social-icon"
                />
              </a>
            </li>
            <li>
              <a
                rel="nofollow noopener"
                href="https://dribbble.com/InterestBud_Solutions"
                target="_blank"
              >
                <FaDribbble
                  style={{ color: '#ea4c89' }}
                  className="icons social-icon"
                />
              </a>
            </li>
            <li>
              <a
                rel="nofollow noopener"
                href="https://www.facebook.com/profile.php?id=100077303503895&sk=about"
                target="_blank"
              >
                <FaFacebook
                  style={{ color: '#3b5999' }}
                  className="icons social-icon"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default KnowMore;
