import React, { useState, useEffect, useRef } from 'react';
import '../Header/Header.css';
import MobileHeader from '../MobileHeader/MobileHeader';
import IBSlogo from '../../Assets/Images/ibs_white.png';
import IBSlogo1 from '../../Assets/Images/ibslogo.png';
import { Link } from 'react-router-dom';
import whatsapp from '../../Assets/Images/whatsapp.png';
import phncall from '../../Assets/Images/phone-call.png';
// import Lines from '../../Components/Lines/Lines'
import aiicon from '../../Assets/Images/aiicon.png'
import mobile from '../../Assets/Images/mob-169x300.png'
import web from '../../Assets/Images/web-300x240.png'
import screen from '../../Assets/Images/Mobile_Screen.png'
import invoicemint from '../../Assets/Images/Invoicemint.png'
import seo from '../../Assets/Images/Group-2174.png'
import blogger from '../../Assets/Images/Group-2175.png'
import native1 from '../../Assets/Images/Group-828.png'
import native2 from '../../Assets/Images/Group-829.png'
import native3 from '../../Assets/Images/Group-829.png'
import native4 from '../../Assets/Images/Group-830.png'
import cpa1 from '../../Assets/Images/Group-832.png'
import cpa2 from '../../Assets/Images/Group-833.png'
import cpa3 from '../../Assets/Images/Group-834.png'
import gaming1 from '../../Assets/Images/Unity-Engine.png'
import gaming3 from '../../Assets/Images/AR_VR.png'
import gaming2 from '../../Assets/Images/2D-Games_3D-Games.png'
import webapp1 from '../../Assets/Images/Group-835.png'
import webapp2 from '../../Assets/Images/Group-836.png'
import webapp3 from '../../Assets/Images/Group-837.png'
import webdevelopement1 from '../../Assets/Images/Group-838.png'
import webdevelopement2 from '../../Assets/Images/Group-839.png'
import webdevelopement3 from '../../Assets/Images/Group-841.png'
import webdevelopement4 from '../../Assets/Images/Group-842.png'
import designing1 from '../../Assets/Images/Group-847.png'
import designing2 from '../../Assets/Images/Group-848.png'
import designing3 from '../../Assets/Images/Group-851.png'
import blockchain1 from '../../Assets/Images/Group-828-1.png'
import blockchain2 from '../../Assets/Images/Group-829-1.png'
import blockchain3 from '../../Assets/Images/Group-831-1.png'
// import blockchain4 from '../../Assets/Images/Group-830-1.png'
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeMegaMenu, setActiveMegaMenu] = useState(null);
  const [submenuTimeout, setSubmenuTimeout] = useState(null);
  const [activeSection, setActiveSection] = useState('blockchain-development');
  const [isButtonScroll, setIsButtonScroll] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation(); // Access current route
  const linkRefs = useRef({});

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    setIsMenuOpen(false);
    setActiveMegaMenu(null);
  }, [location]);

  // Scroll event for header with route-specific logic
  useEffect(() => {
    const isOnAIPage = location.pathname === '/developing-with-ai';

    if (isOnAIPage) {
      // Ensure header stays in "before-scroll" state
      setIsScrolled(false);
      setIsButtonScroll(false);
      return; // Skip attaching the scroll listener
    }

    const handleScroll = () => {
      const scrollPosition = window.scrollY; // Get current scroll position
      const triggerHeight = window.innerHeight; // 100vh in pixels

      setIsScrolled(scrollPosition >= triggerHeight); // Check if scrolled down at all
      setIsButtonScroll(scrollPosition >= triggerHeight); // Trigger event at 100vh or beyond
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname]); // Re-run when the route changes

  const toggleMegaMenu = (menu) => {
    setActiveMegaMenu(activeMegaMenu === menu ? null : menu);
  };

  const handleMouseEnter = (menu) => {
    if (submenuTimeout) {
      clearTimeout(submenuTimeout);
    }
    setActiveMegaMenu(menu);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setActiveMegaMenu(null);
    }, 300);
    setSubmenuTimeout(timeout);
  };

  const handleClick = (menu, linkId) => {
    setActiveMenu(menu);

    // Remove hover effect from all links first
    const allLinks = document.querySelectorAll(".native-apps");
    allLinks.forEach(link => link.classList.remove("hover-effect"));

    // Add hover effect only to the clicked link
    if (linkRefs.current[linkId]) {
      linkRefs.current[linkId].classList.add("hover-effect");
    }
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false); // Close the mega menu when a link is clicked
  };


  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <header className={`header-com ${isScrolled ? 'scrolled' : ''}`} id="header-com">
            <div className="logo">
              <Link to="/"><img src={IBSlogo} alt="Logo" className={`logo-white ${isScrolled ? 'scrolled' : ''}`} /></Link>
              <Link to="/"><img src={IBSlogo1} alt="Logo" className={`logo-color ${isScrolled ? 'scrolled' : ''}`} /></Link>
              <div
                // className="logoimgtex"
                className={`logoimgtex ${isButtonScroll ? 'show' : ''}`}>            <Link to="/developing-with-ai" id="line-removal"><span>Developing Faster With AI <img src={aiicon} alt="" /></span></Link> 
              </div>
            </div>
            <nav className={`navbar ${isNavOpen ? 'show' : ''}`}>
              <ul id="nav-list" className="navbar-links">
                <li onClick={() => toggleMegaMenu('about')}>
                  <Link to='/about'
                    onClick={() => { handleLinkClick('about'); }}
                    className={activeLink === 'about' ? 'active-link' : ''}
                  >About Us</Link>
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter('marketing')}
                  onMouseLeave={handleMouseLeave}                 >
                  <Link to=''
                    onClick={() => { handleLinkClick('marketing'); }}
                    className={activeLink === 'marketing' ? 'active-link' : ''}
                  >Marketing</Link>
                  {activeMegaMenu === 'marketing' && (
                    <div className="mega-menu" id="marketing">
                      <ul>
                        <li><Link to="/marketing/seo"
                          onClick={() => { handleLinkClick('seo'); }}
                          className={activeLink === 'seo' ? 'active-link' : ''}
                        ><img src={seo} alt="" />&nbsp;&nbsp;SEO</Link></li>
                        <li><Link to="/marketing/blogger-outreach"
                          onClick={() => { handleLinkClick('blogger-outreach'); }}
                          className={activeLink === 'blogger-outreach' ? 'active-link' : ''}
                        ><img src={blogger} alt="" />&nbsp;&nbsp;Blogger Outreach Services</Link></li>
                      </ul>
                    </div>
                  )}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter('portfolio')}
                  onMouseLeave={handleMouseLeave}                >
                  <Link to='/portfolio'
                    onClick={() => { handleLinkClick('portfolio'); }}
                    className={activeLink === 'portfolio' ? 'active-link' : ''}
                  >Portfolio</Link>
                  {activeMegaMenu === 'portfolio' && (
                    <div className="mega-menu" id="portfolio-mega-menu">
                      <ul>
                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['Mobile-Apps'] = el}
                              className="native-apps"
                              onClick={() => handleClick('Mobile-Apps', 'Mobile-Apps')}
                              onMouseEnter={() => handleClick('Mobile-Apps', 'Mobile-Apps')}
                            >Mobile Apps</Link>
                          </div>
                          <ul className="submenu open" id="portfolio">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-4">
                                <Link to="/Mobile-Portfolio">
                                <img src={mobile} alt="" />
                                </Link>
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </ul>
                        </li>
                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['Website'] = el}
                              className="native-apps"
                              onClick={() => handleClick('Website', 'Website')}
                              onMouseEnter={() => handleClick('Website', 'Website')}
                            >Website</Link>
                          </div>
                          <ul className="submenu" id="portfolio">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-4">
                                <img src={web} alt="" />
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </ul>
                        </li>
                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['Gaming'] = el}
                              className="native-apps"
                              onClick={() => handleClick('Gaming', 'Gaming')}
                              onMouseEnter={() => handleClick('Gaming', 'Gaming')}
                            >Gaming</Link>
                          </div>
                          <ul className="submenu" id="portfolio">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-4">
                                <img src={screen} alt="" />
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </ul>
                        </li>
                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['Our-Products'] = el}
                              className="native-apps"
                              onClick={() => handleClick('Our-Products', 'Our-Products')}
                              onMouseEnter={() => handleClick('Our-Products', 'Our-Products')}
                            >Our Products</Link>
                          </div>
                          <ul className="submenu" id="portfolio">
                            <div className="row">
                              <div className="col-md-4"></div>
                              <div className="col-md-4">
                                <img src={invoicemint} alt="" />
                              </div>
                              <div className="col-md-4"></div>
                            </div>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li
                  onMouseEnter={() => handleMouseEnter('service')}
                  onMouseLeave={handleMouseLeave}
                >
                  <Link to=''>Services</Link>
                  {activeMegaMenu === 'service' && (
                    <div className={`mega-menu ${isMenuOpen ? 'open' : 'closed'}`} id="service">
                      <ul>
                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['native-apps'] = el}
                              className="native-apps"
                              onClick={() => { handleClick('native-apps', 'native-apps') }}
                              onMouseEnter={() => handleClick('native-apps', 'native-apps')}
                            >
                              Native Apps
                            </Link>
                          </div>
                          <ul className="submenu open">
                            <div className="row">
                              <div className="col-md-6">
                                <h1 id="submenu-h1">Native Apps</h1>
                                <li
                                  onClick={() => { handleMenuClose() }}
                                ><Link to="/services/native-apps/Mobile-development"
                                  onClick={() => { handleLinkClick('Mobile-App-Development'); }}
                                  className={activeLink === 'Mobile-App-Development' ? 'active-link' : ''}
                                ><img src={native1} alt="" />Mobile App Development</Link></li>
                                <li><Link to="/services/native-apps/ios"
                                  onClick={() => { handleLinkClick('Native-iOS-Apps'); }}
                                  className={activeLink === 'Native-iOS-Apps' ? 'active-link' : ''}
                                ><img src={native2} alt="" />Native iOS Apps</Link></li>
                                <li><Link to="/services/android"
                                  onClick={() => { handleLinkClick('Android-App-Apps'); }}
                                  className={activeLink === 'Android-App-Apps' ? 'active-link' : ''}
                                ><img src={native3} alt="" />Android App Apps</Link></li>
                                <li><Link to="/services/wearable"
                                  onClick={() => { handleLinkClick('Wearable-App-Development'); }}
                                  className={activeLink === 'Wearable-App-Development' ? 'active-link' : ''}
                                ><img src={native4} alt="" />Wearable App Development</Link></li>
                              </div>
                              <div className="col-md-1">
                                <div className='bfhgd'></div>
                              </div>
                              <div className="col-md-5">
                                <img src="https://www.interestbudsolutions.com/wp-content/uploads/2023/11/App-development-1.gif" alt="" />
                              </div>
                            </div>
                          </ul>
                        </li>
                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['cross-platform'] = el}
                              className="native-apps"
                              onClick={() => handleClick('cross-platform', 'cross-platform')}
                              onMouseEnter={() => handleClick('cross-platform', 'cross-platform')}>
                              Cross Platform
                            </Link>
                          </div>
                          <ul className="submenu">
                            <div className="row">
                              <div className="col-md-6">
                                <h1 id="submenu-h1">Cross Platform</h1>
                                <li><Link to="/services/native-apps/Mobile-development"
                                  onClick={() => { handleLinkClick('Xamarin'); }}
                                  className={activeLink === 'Xamarin' ? 'active-link' : ''}
                                ><img src={cpa1} alt="" />Xamarin</Link></li>
                                <li><Link to="/services/native-apps/Mobile-development"
                                  onClick={() => { handleLinkClick('React-Native'); }}
                                  className={activeLink === 'React-Native' ? 'active-link' : ''}
                                ><img src={cpa2} alt="" />React Native</Link></li>
                                <li><Link to="/services/native-apps/Mobile-development"
                                  onClick={() => { handleLinkClick('Flutter-App-Developemnt'); }}
                                  className={activeLink === 'Flutter-App-Developemnt' ? 'active-link' : ''}
                                ><img src={cpa3} alt="" />Flutter App Developemnt</Link></li>
                              </div>
                              <div className="col-md-1">
                                <div className='bfhgd'></div>
                              </div>
                              <div className="col-md-5">
                                <img src="https://www.interestbudsolutions.com/wp-content/uploads/2023/11/Mobile-development-1.gif" alt="" />
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['gaming-apps'] = el}
                              className="native-apps"
                              onClick={() => handleClick('gaming-apps', 'gaming-apps')}
                              onMouseEnter={() => handleClick('gaming-apps', 'gaming-apps')}
                            >
                              Gaming Apps
                            </Link>
                          </div>
                          <ul className="submenu">
                            <div className="row">
                              <div className="col-md-6">
                                <h1 id="submenu-h1">Gaming Apps</h1>
                                <li><Link to="/services/unity-gaming"
                                  onClick={() => { handleLinkClick('Unity-Engines'); }}
                                  className={activeLink === 'Unity-Engines' ? 'active-link' : ''}
                                ><img src={gaming1} alt="" />Unity Engines</Link></li>
                                <li><Link to="/services/unity-gaming"
                                  onClick={() => { handleLinkClick('2D/3D-Games'); }}
                                  className={activeLink === '2D/3D-Games' ? 'active-link' : ''}
                                ><img src={gaming2} alt="" />2D/3D Games</Link></li>
                                <li><Link to="/services/unity-gaming"
                                  onClick={() => { handleLinkClick('AR/VR-games'); }}
                                  className={activeLink === 'AR/VR-games' ? 'active-link' : ''}
                                ><img src={gaming3} alt="" />AR/VR games</Link></li>
                              </div>
                              <div className="col-md-1">
                                <div className='bfhgd'></div>
                              </div>
                              <div className="col-md-5">
                                <img src="https://www.interestbudsolutions.com/wp-content/uploads/2023/11/Game-analytics-1-1.gif" alt="" />
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['web-apps'] = el}
                              className="native-apps"
                              onClick={() => handleClick('web-apps', 'web-apps')}
                              onMouseEnter={() => handleClick('web-apps', 'web-apps')}
                            >
                              Web Apps
                            </Link>
                          </div>
                          <ul className="submenu">
                            <div className="row">
                              <div className="col-md-6">
                                <h1 id="submenu-h1">Web Apps</h1>
                                <li><Link to="/services/website"
                                  onClick={() => { handleLinkClick('Angular'); }}
                                  className={activeLink === 'Angular' ? 'active-link' : ''}
                                ><img src={webapp1} alt="" />Angular</Link></li>
                                <li><Link to="/services/website"
                                  onClick={() => { handleLinkClick('React-JS'); }}
                                  className={activeLink === 'React-JS' ? 'active-link' : ''}
                                ><img src={webapp2} alt="" />React JS</Link></li>
                                <li><Link to="/services/website"
                                  onClick={() => { handleLinkClick('PHP'); }}
                                  className={activeLink === 'PHP' ? 'active-link' : ''}
                                ><img src={webapp3} alt="" />PHP</Link></li>
                              </div>
                              <div className="col-md-1">
                                <div className='bfhgd'></div>
                              </div>
                              <div className="col-md-5">
                                <img src="https://www.interestbudsolutions.com/wp-content/uploads/2023/11/Mobile-browsers.gif" alt="" />
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['website-development'] = el}
                              className="native-apps"
                              onClick={() => handleClick('website-development', 'website-development')}
                              onMouseEnter={() => handleClick('website-development', 'website-development')}
                            >
                              Website Development
                            </Link>
                          </div>
                          <ul className="submenu">
                            <div className="row">
                              <div className="col-md-6">
                                <h1 id="submenu-h1">Website Apps</h1>
                                <li><Link to="/services/website"
                                  onClick={() => { handleLinkClick('PHP'); }}
                                  className={activeLink === 'PHP' ? 'active-link' : ''}
                                ><img src={webdevelopement1} alt="" />PHP</Link></li>
                                <li><Link to="/services/website"
                                  onClick={() => { handleLinkClick('Code-Ignitor'); }}
                                  className={activeLink === 'Code-Ignitor' ? 'active-link' : ''}
                                ><img src={webdevelopement2} alt="" />Code Ignitor</Link></li>
                                <li><Link to="/services/website"
                                  onClick={() => { handleLinkClick('Laravel'); }}
                                  className={activeLink === 'Laravel' ? 'active-link' : ''}><img src={webdevelopement3} alt="" />Laravel</Link></li>
                                <li><Link to="/services/website"
                                  onClick={() => { handleLinkClick('Cake-PHP'); }}
                                  className={activeLink === 'Cake-PHP' ? 'active-link' : ''}
                                ><img src={webdevelopement4} alt="" />Cake PHP</Link></li>
                              </div>
                              <div className="col-md-1">
                                <div className='bfhgd'></div>
                              </div>
                              <div className="col-md-5">
                                <img src="https://www.interestbudsolutions.com/wp-content/uploads/2023/11/Website-designer.gif" alt="" />
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['designing'] = el}
                              className="native-apps"
                              onClick={() => handleClick('designing', 'designing')}
                              onMouseEnter={() => handleClick('designing', 'designing')}
                            >
                              Designing
                            </Link>
                          </div>
                          <ul className="submenu">
                            <div className="row">
                              <div className="col-md-6">
                                <h1 id="submenu-h1">Designing</h1>
                                <li><Link to="/services/designing"
                                  onClick={() => { handleLinkClick('UI/UX'); }}
                                  className={activeLink === 'UI/UX' ? 'active-link' : ''}
                                ><img src={designing1} alt="" />UI/UX</Link></li>
                                <li><Link to="/services/designing"
                                  onClick={() => { handleLinkClick('Logo-Design'); }}
                                  className={activeLink === 'Logo-Design' ? 'active-link' : ''}
                                ><img src={designing2} alt="" />Logo Design</Link></li>
                                <li><Link to="/services/designing"><img src={designing3} alt="" />Graphic Design</Link></li>
                              </div>
                              <div className="col-md-1">
                                <div className='bfhgd'></div>
                              </div>
                              <div className="col-md-5">
                                <img src="https://www.interestbudsolutions.com/wp-content/uploads/2023/11/Graphic-design-1.gif" alt="" />
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li>
                          <div className="newmega">
                            <Link
                              to=""
                              ref={(el) => linkRefs.current['blockchain-token'] = el}
                              className="native-apps"
                              onClick={() => handleClick('blockchain-token', 'blockchain-token')}
                              onMouseEnter={() => handleClick('blockchain-token', 'blockchain-token')}
                            >
                              Blockchain And Token
                            </Link>
                          </div>
                          <ul className="submenu">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="blockchain-btns">
                                  <button
                                    onClick={() => handleSectionChange('blockchain-development')}
                                    className={activeSection === 'blockchain-development' ? 'active' : ''}
                                  >
                                    Blockchain Development
                                  </button>
                                  <button
                                    onClick={() => handleSectionChange('token-development')}
                                    className={activeSection === 'token-development' ? 'active' : ''}
                                  >
                                    Token Development
                                  </button>
                                  <button
                                    onClick={() => handleSectionChange('other-development')}
                                    className={activeSection === 'other-development' ? 'active' : ''}
                                  >
                                    Other Development
                                  </button>
                                </div>

                                {activeSection === 'blockchain-development' && (
                                  <div className="blockchain-development">
                                    <li><Link to="/services/blockchain-development"
                                      onClick={() => { handleLinkClick('Blockchain-Development'); }}
                                      className={activeLink === 'Blockchain-Development' ? 'active-link' : ''}
                                    ><img src={blockchain1} alt="" />Blockchain Development</Link></li>
                                    <li><Link to="/services/ALT-coin-development"
                                      onClick={() => { handleLinkClick('Alt-Coin-Development-Service'); }}
                                      className={activeLink === 'Alt-Coin-Development-Service' ? 'active-link' : ''}
                                    ><img src={blockchain2} alt="" />Alt Coin Development Service</Link></li>
                                    <li><Link to="/services/multichain-blockchain-development"

                                      onClick={() => { handleLinkClick('Multichain-Blockchain'); }}
                                      className={activeLink === 'Multichain-Blockchain' ? 'active-link' : ''}
                                    ><img src={blockchain2} alt="" />Multichain Blockchain</Link></li>
                                    <li><Link to="/services/crypto-mlm-software-development"
                                      onClick={() => { handleLinkClick('crypto-mlm-software-development'); }}
                                      className={activeLink === 'crypto-mlm-software-development' ? 'active-link' : ''}
                                    ><img src={blockchain2} alt="" />Crypto MLM Software Development</Link></li>
                                    <li><Link to="/services/smart-contract-development"
                                      onClick={() => { handleLinkClick('smart-contract-development'); }}
                                      className={activeLink === 'smart-contract-development' ? 'active-link' : ''}
                                    ><img src={blockchain2} alt="" />Smart Contract Developemnt</Link></li>
                                    <li><Link to="/services/crypto-wallet-development"
                                      onClick={() => { handleLinkClick('crypto-wallet-development'); }}
                                      className={activeLink === 'crypto-wallet-development' ? 'active-link' : ''}
                                    ><img src={blockchain2} alt="" />Crypto Wallet Development</Link></li>
                                    <li><Link to="/services/crypto-currency-exchange-development"
                                      onClick={() => { handleLinkClick('crypto-currency-exchange-development'); }}
                                      className={activeLink === 'crypto-currency-exchange-development' ? 'active-link' : ''}
                                    ><img src={blockchain2} alt="" />Crypto Exchange Services</Link></li>
                                  </div>
                                )}

                                {activeSection === 'token-development' && (
                                  <div className="token-development">
                                    <li><Link to="/Crypto-Token-Development"
                                      onClick={() => { handleLinkClick('crypto-token'); }}
                                      className={activeLink === 'crypto-token' ? 'active-link' : ''}
                                    ><img src={blockchain1} alt="" />Crypto Token Development</Link></li>
                                    <li><Link to="/services/tokenization"
                                      onClick={() => { handleLinkClick('tokenization'); }}
                                      className={activeLink === 'tokenization' ? 'active-link' : ''}
                                    ><img src={blockchain2} alt="" />ERC-20 Token Development</Link></li>
                                    <li><Link to="/services/tokenization"><img src={blockchain2} alt="" />Ethereum Token Development</Link></li>
                                    <li><Link to="/services/tokenization"><img src={blockchain2} alt="" />Solana Token Development</Link></li>
                                    <li><Link to="/services/tokenization"><img src={blockchain2} alt="" />Tron Token Devellopment</Link></li>
                                  </div>
                                )}

                                {activeSection === 'other-development' && (
                                  <div className="other-development">
                                    <li><Link to="/crypto-whitepaper"><img src={blockchain3} alt="" />Crypto White Paper Development</Link></li>
                                    <li><Link to="/crypto-whitepaper"><img src={blockchain3} alt="" />Polygon Blockchain Development</Link></li>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-1">
                                <div className='bfhgd'></div>
                              </div>
                              <div className="col-md-5">
                                <img src="https://www.interestbudsolutions.com/wp-content/uploads/2023/11/Bitcoin-P2P.gif" alt="" />
                              </div>
                            </div>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
                <li className="connect-btns"><Link to="/contact"
                  onClick={() => { handleLinkClick('contact'); }}
                  className={activeLink === 'contact' ? 'active-link' : ''}
                >Contact Us</Link></li>
                <div className="listimg">
                  <li><Link to="https://wa.me"><img src={whatsapp} alt="WhatsApp" /></Link></li>
                  <div className="sep"></div>
                  <li className="dropdowns">
                    <img src={phncall} alt="Phone Call" />
                    <div className="dropdowns-contents">
                      <h3>Write To Us</h3>
                      <b style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        fontFamily: 'poppins'
                      }}>support@interestbudsolutions.com</b>
                      <h3>We are just a call away</h3>
                      <p>+91 8076569119 (Sales)<br />+91 9001761400 (HR)</p>
                    </div>
                  </li>
                </div>
              </ul>
            </nav>
            <div className="hamburger" id="hamburger" onClick={toggleNav}>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </header>
        </div>
      </div>
      {/* mobileresponshive header ... .. */}
      <MobileHeader />
    </div>
  );
};

export default Header;
